<template>
  <div>
    <div class="limiter">
      <div class="container-table100">
        <div class="wrap-table100">
          <div class="table100">
            <table>
              <thead>
                <tr class="table100-head">
                  <th class="column1">Día</th>
                  <th class="column2">Enviar</th>
                  <th class="column3">Frecuencia</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(day, index) in days" :key="index">
                  <td class="column1">{{ day }}</td>
                  <td class="column2">
                    <v-checkbox
                      v-model="selectedDays"
                      color="indigo"
                      :value="day"
                      hide-details
                    ></v-checkbox>
                  </td>
                  <td class="column3">
                    <v-text-field
                      style="max-width: 90px"
                      name="name"
                      single-line
                      type="number"
                    ></v-text-field>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="table100 mt-3">
            <table>
              <thead>
                <tr class="table100-head">
                  <th class="column1">Día</th>
                  <th class="column2">Enviar</th>
                  <th class="column3">Frecuencia</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(category, index2) in $store.state.categoriesModule
                    .categories"
                  :key="index2"
                >
                  <td class="column1">{{ category.name }}</td>
                  <td class="column2">
                    <v-checkbox
                      v-model="selectedDays"
                      color="indigo"
                      :value="day"
                      hide-details
                    ></v-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      days: ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes"],
      selectedDays: [],
    };
  },
};
</script>

<style lang="scss" scoped>
/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: OpenSans-Regular;
  src: url("/fonts/OpenSans/OpenSans-Regular.ttf");
}

/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  font-family: sans-serif;
}

/* ------------------------------------ */
a {
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
}

/* ------------------------------------ */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/* ------------------------------------ */
input {
  display: block;
  outline: none;
  border: none !important;
}

textarea {
  display: block;
  outline: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

/* ------------------------------------ */
button {
  outline: none !important;
  border: none;
  background: transparent;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ Utiliti ]*/

/*//////////////////////////////////////////////////////////////////
[ Table ]*/

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-table100 {
  width: 100%;
  min-height: 100vh;
  background: #c850c0;
  background: -webkit-linear-gradient(45deg, #4158d0, #c850c0);
  background: -o-linear-gradient(45deg, #4158d0, #c850c0);
  background: -moz-linear-gradient(45deg, #4158d0, #c850c0);
  background: linear-gradient(45deg, #4158d0, #c850c0);

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 33px 30px;
}

.wrap-table100 {
  width: 500px;
}

table {
  border-spacing: 1;
  border-collapse: collapse;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
table * {
  position: relative;
}
table td,
table th {
  padding-left: 8px;
}
table thead tr {
  height: 60px;
  background: #36304a;
}
table tbody tr {
  height: 50px;
}
table tbody tr:last-child {
  border: 0;
}
table td,
table th {
  text-align: left;
}
table td.l,
table th.l {
  text-align: right;
}
table td.c,
table th.c {
  text-align: center;
}
table td.r,
table th.r {
  text-align: center;
}

.table100-head th {
  font-family: OpenSans-Regular;
  font-size: 18px;
  color: #fff;
  line-height: 1.2;
  font-weight: unset;
}

tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}

tbody tr {
  font-family: OpenSans-Regular;
  font-size: 15px;
  color: #808080;
  line-height: 1.2;
  font-weight: unset;
}

.column1 {
  width: 260px;
  padding-left: 40px;
}

.column2 {
  width: 160px;
}

.column3 {
  width: 245px;
}

.column4 {
  width: 110px;
  text-align: right;
}

.column5 {
  width: 170px;
  text-align: right;
}

.column6 {
  width: 222px;
  text-align: right;
  padding-right: 62px;
}

@media (max-width: 576px) {
  .container-table100 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>